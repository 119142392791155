import React from "react"

interface types {
	width?: number | string | undefined;
	height?: number | string | undefined;
	className?: string | undefined;
}

const Angle = ({width= 34, height = 31, className = ""}:types) => {
	return (
		<svg width={width} height={height} className={className} viewBox="0 0 34 31" fill="none" xmlns="http://www.w3.org/2000/svg">
			<path d="M2 31V2H34" stroke="#D7FC51" strokeWidth="3"/>
		</svg>

	)
}

export default (Angle)
