import React, {memo} from "react"
import style from "styles/pageNotFound.module.css"
import useLocale from '../../hooks/useLocale'
import Angle from '../icons/Angle'
import {useRouter} from 'next/router'

const Page404 = () => {
	const router = useRouter()
	const {pageNotFound} = useLocale()

	return (
		<div className={style.notfound}>
			<div className={style.bg}>
				<video
					autoPlay={true}
					muted={true}
					loop={true}
					playsInline={true}
					className={style.video}
				>
					<source src="/video/404.mp4" type="video/mp4"/>
				</video>
				<div className={style.container}>
					<div className={style.content}>
						<div>
							<div
								data-aos-once="true"
								data-aos="fade-right"
								data-aos-offset="100"
								data-aos-easing="ease-in-sine"
								className={style.title}>{pageNotFound.title}</div>
							<div
								data-aos-once="true"
								data-aos="fade-left"
								data-aos-offset="100"
								data-aos-easing="ease-in-sine"
								className={style.subtitle}>{pageNotFound.subtitle}</div>
							<div
								data-aos-once="true"
								data-aos="fade-right"
								data-aos-offset="100"
								data-aos-easing="ease-in-sine">
								<button
									onClick={() => {
										router.push('/')
									}}
									className={style.button}>
									{pageNotFound.button}
								</button>
							</div>
						</div>

						<div
							data-aos-once="true"
							data-aos="fade-down-right"
							data-aos-offset="100"
							data-aos-easing="ease-in-sine"
							className="absolute top-10 left-0"
						>
							
							<Angle />
						</div>

						<div
							data-aos-once="true"
							data-aos="fade-down-left"
							data-aos-offset="100"
							data-aos-easing="ease-in-sine"
							className="absolute top-10 right-0"
						>
							<Angle className="rotate-90"/>
						</div>

						<div
							data-aos-once="true"
							data-aos="fade-up-left"
							data-aos-anchor-placement="center"
							className="absolute bottom-28 right-0"
						>
							<Angle className="rotate-180"/>
						</div>

						<div
							data-aos-once="true"
							data-aos="fade-up-right"
							data-aos-anchor-placement="center"
							className="absolute bottom-28 left-0"
						>
							<Angle className="-rotate-90"/>
						</div>
						
					</div>
				</div>

				<div className={style.fade}/>
			</div>
		</div>
	)
}

export default memo(Page404)
